<template>
	<el-dialog
		title="Nueva tarea"
		:visible="showDialog"
		@close="close"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
	>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row>
				<v-col cols="12" xl="6">
					<div class="form-group" :class="{ 'error--text': errors.class }">
						<label>Tarea</label>
						<el-select v-model="form.class" dusk="class">
							<el-option
								v-for="option in commnads"
								:key="option.name"
								:value="option.class"
								:label="option.name"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.class"
							v-text="errors.class[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="6">
					<div class="form-group" :class="{ 'error--text': errors.execution_time }">
						<label>Hora de ejecución</label>
						<el-time-picker
							v-model="execution_time"
							format="HH:mm"
							placeholder="Seleccionar"
							dusk="execution_time"
							@change="setTime"
						></el-time-picker>
						<small
							class="error--text"
							v-if="errors.execution_time"
							v-text="errors.execution_time[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading_submit">
						<template v-if="loading_submit">
							Creando tarea...
						</template>
						<template v-else>
							Crear
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
import { confirm } from 'Mixins/confirm';
import dayjs from 'dayjs';

export default {
	props: ['showDialog'],
	mixins: [confirm],
	data() {
		return {
			loading_submit: false,
			execution_time: null,
			resource: 'tasks',
			errors: {},
			commnads: [],
			form: {},
		};
	},
	created() {
		this.$http
			.get(`/${this.resource}/commands`)
			.then((response) => {
				this.commnads = response.data;
			})
			.catch((error) => {
				console.log(error);
			})
			.then(() => {});
	},
	methods: {
		setTime(timePicker) {
			this.form.execution_time = `${dayjs(timePicker).format('HH:mm')}:00`;
		},
		submit() {
			this.confirm().then(() => {
				this.loading_submit = true;
				this.$http
					.post(`/${this.resource}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							this.close();

							this.form.class = null;
							this.execution_time = null;
							this.form.execution_time = null;

							this.errors = {};

							this.$eventHub.$emit('reloadData', this.resource);
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						if (error.response.status === 422) {
							this.errors = error.response.data.errors;
						} else {
							this.$message.error(error.response.data.message);
						}
					})
					.then(() => {
						this.loading_submit = false;
					});
			});
		},
		close() {
			this.$emit('update:showDialog', false);
		},
	},
};
</script>
