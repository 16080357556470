<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Tareas programadas</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" @click.prevent="clickCreate()">Nuevo</v-btn>
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<emb-data-table-without-filter :resource="resource">
				<tr slot="heading">
					<th>N°</th>
					<th>Clase</th>
					<th class="text-right">Hora de ejecución</th>
					<th>Ultima ejecución</th>
					<th>Log</th>
					<th class="text-right">Acciones</th>
				</tr>
				<tr slot-scope="{ index, row }" :key="index">
					<td>{{ index }}</td>
					<td>{{ row.class }}</td>
					<td class="text-right">{{ row.execution_time }}</td>
					<td>{{ row.updated_at }}</td>
					<td>{{ row.output }}</td>
					<td class="text-right">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="accent"
									class="ma-1"
									fab
									x-small
									dark
									@click.prevent="clickDelete(row.id)"
									v-on="on"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span>Eliminar</span>
						</v-tooltip>
					</td>
				</tr>
			</emb-data-table-without-filter>
		</v-card>

		<task-form :showDialog.sync="showDialog"> </task-form>
	</div>
</template>

<script>
import { confirm } from 'Mixins/confirm';
import TaskForm from './Form.vue';

export default {
	mixins: [confirm],
	components: {
		TaskForm,
	},
	data() {
		return {
			resource: 'tasks',
			showDialog: false,
		};
	},

	methods: {
		clickCreate() {
			this.showDialog = true;
		},
		clickDelete(id) {
			this.confirm().then(() => {
				this.$http
					.delete(`/${this.resource}/${id}`)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							this.$eventHub.$emit('reloadData', this.resource);
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						this.$message.error(error.response.data.message);
					})
					.then(() => {});
			});
		},
	},
};
</script>
